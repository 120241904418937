<!--
* @Description:
-->
<template>
  <div class="nav_side">
    <nav>
      <router-link active-class="active" to="/home">首页</router-link>
      <router-link active-class="active" to="/trainingClass">
        培训班次
      </router-link>
      <router-link active-class="active" to="/studyCenter"
        >学习中心</router-link
      >
      <router-link active-class="active" to="/examCenter">考试中心</router-link>
      <router-link active-class="active" to="/newcomerPost"
        >新人驿站</router-link
      >
      <router-link active-class="active" to="/liveRoom">直播大厅</router-link>
      <router-link active-class="active" to="/boutiqueCourse"
        >精品课程</router-link
      >
      <router-link active-class="active" to="/trainDynamic"
        >培训动态</router-link
      >
      <router-link active-class="active" to="/helpCenter">帮助中心</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  watch: {},
  data () {
    return {}
  },
  methods: {},
  mounted () {},
}
</script>

<style scoped lang="scss">
.nav_side {
  width: 1240px;
  margin: 0 auto;
  height: 61px;
  line-height: 61px;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 22px;
    font-weight: bold;
  }

  nav a {
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    line-height: 61px;
  }

  nav a:hover {
    opacity: 1;
  }

  .active {
    font-size: 26px;
    font-weight: bold;
    color: #ffffff;
    opacity: 1;
    border-bottom: 3px solid #ffffff;
  }
}
</style>
